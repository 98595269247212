import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['link']

  click(event) {
    this.linkTarget.addEventListener('ajax:success', this.handleSuccess.bind(this))
  }

  handleSuccess(event) {
    this.element.parentNode.removeChild(this.element)
  }
}
