import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['layersContainer', 'haveItCount', 'wantItCount']

  updateImage() {
    const productComponentId = event.target.dataset.productComponentId
    const productComponentImage = event.target.dataset.productComponentImage

    const showPaywall = event.target.dataset.index > 0
    $('#product-paywall-overlay').toggle(showPaywall)

    this.updateLayerImage(productComponentId, productComponentImage)
  }

  updatePageWithSelections() {
    const visualizerForm = $('#visualizer-form');

    $.ajax({
      type: 'GET',
      url: `${visualizerForm.attr('action')}/update_page_with_selections`,
      data: visualizerForm.serialize(),
      dataType: 'script'
    })
  }

  updateLayerImage(productComponentId, productComponentImage) {
    $(`[data-product-component-layer="${productComponentId}"]`).attr('src', productComponentImage)
  }
}
