import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "textField" ]


  toggleTextFieldVisibility() {
    if (event.target.value == '') {
      $(this.textFieldTarget).removeClass('d-none')
    } else {
      $(this.textFieldTarget).addClass('d-none')
      $(this.textFieldTarget).find(':input').val('')
    }
  }
}
