import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["source"]

  copy(event) {
    event.preventDefault()
    navigator.clipboard.writeText(this.sourceTarget.href)
    alert('Link copied!')
  }
}
