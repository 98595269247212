import { Controller } from "stimulus"

// Given a url, will add more results to entries target, when the pagination target link is clicked, and hide the link when there are no more next results.
export default class extends Controller {
  static targets = ["entries", "pagination"]

  /*
   * To use:
   * load-more-url: url to load more from.
   * entries: target the container for the entries.
   * pagination: target with `page` data on it.
   *
   * Return json with:
   * entries: html string of additional entries.
   * next_page: page.next result.
   */
  loadMore() {
    const url = this.data.get('url')
    const page = this.paginationTarget.dataset.page

    $.ajax({
      type: 'GET',
      url: url,
      data: { page: page },
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
        if (data.next_page) {
          this.paginationTarget.dataset.page = data.next_page
        } else {
          $(this.paginationTarget).hide()
        }
      }
    })
  }
}
