import { Controller } from "stimulus"
import { loadStripe } from "@stripe/stripe-js/pure"

export default class extends Controller {
  static targets = ['cardElement', 'cardErrors', 'form', 'submitButton', 'promoCode', 'validatedPromoCode', 'promoPercentOff', 'couponName', 'subscriptionPrice', 'option']

  connect() {
    loadStripe(this.data.get('key')).then(stripe => {

      var elements = stripe.elements()
      var style = {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }

      var card = elements.create('card', {
        style: style
      })

      card.mount(this.cardElementTarget)

      // Handle real-time validation errors from the card Element.
      let controller = this
      card.addEventListener('change', (event) => {
        var displayError = controller.cardErrorsTarget
        if (event.error) {
          displayError.textContent = event.error.message
        } else {
          displayError.textContent = ''
        }
      })

      // Handle form submission.
      var form = controller.formTarget
      form.addEventListener('submit', (event) => {
        event.preventDefault()
        controller.submitButtonTarget.disabled = true

        stripe.createToken(card).then((result) => {
          if (result.error) {
            // Inform the user if there was an error.
            controller.cardErrorsTarget.textContent = result.error.message

            controller.submitButtonTarget.disabled = false
            $(controller.submitButtonTarget).removeAttr('disabled');
          } else {
            // Send the token to your server.
            controller.stripeTokenHandler(result.token)
          }
        })
      })
      this.updateTotal()
    })
  }

  selectSubscription() {
    this.updateTotal()
  }

  // Submit the form with the token ID.
  stripeTokenHandler(token) {
    // Insert the token ID into the form so it gets submitted to the server
    var form = this.formTarget
    var hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', 'stripeToken')
    hiddenInput.setAttribute('value', token.id)
    form.appendChild(hiddenInput);

    let controller = this;
    ['brand', 'exp_month', 'exp_year', 'last4'].forEach((field) => {
      controller.addFieldToForm(form, token, field)
    })

    form.submit()
  }

  addFieldToForm(form, token, field) {
    var hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', "card_" + field)
    hiddenInput.setAttribute('value', token.card[field])
    form.appendChild(hiddenInput)
  }

  validatePromoCode() {
    event.preventDefault()
    const validatedPromoCode = this.validatedPromoCodeTarget
    const couponName = this.couponNameTarget
    validatedPromoCode.value = ''
    validatedPromoCode.dataset.percentOff = 0
    $(couponName).html('')
    let controller = this

    $.ajax({
      url: '/subscriptions/validate_promo_code',
      type: 'get',
      data: { promo_code: this.promoCodeTarget.value },
      success: (response) => {
        couponName.textContent = response.coupon.name
        validatedPromoCode.value = response.id

        validatedPromoCode.dataset.percentOff = response.coupon.percent_off
        controller.updateTotal()
      },
      error: function(XMLHttpRequest, textStatus, errorThrown) {
        controller.updateTotal()
        couponName.textContent = 'The code you have entered is not valid.'
      }
    })
  }

  updateTotal() {
    const percentOff = this.validatedPromoCodeTarget.dataset.percentOff / 100
    const subscriptionPrice = $('input[name="product_option"]:checked').data('value')
    this.subscriptionPriceTarget.textContent = (subscriptionPrice - subscriptionPrice * percentOff).toFixed(2)
  }
}
