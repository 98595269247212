import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container']

  connect() {
    $.ajax({
      type: 'GET',
      url: this.data.get('url'),
      success: (response) => {
        $(this.containerTarget).html(response)
      }
    })
  }
}
