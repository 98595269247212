import { Controller } from 'stimulus'

export default class extends Controller {
  toggle(event) {
    event.preventDefault()

    $.ajax({
      url: this.data.get('toggle-favorite-url'),
      type: 'post',
      data: { id: this.data.get('id') },
      success: () => { this.element.classList.toggle('fa-heart-o') }
    })
  }
}
